@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

/*
* New values used for ZB Portal's 2024 redesign.
* After redesign is complete, this can replace the old '_zb-variables.scss'.
* New values needed for ZB redesign should be added here.
* These will be used when ZBPortal2024 feature flag is enabled.
*/

$brandName: 'zb-redesign';
$brandTest: green;

/* Bootstrap */
$primary: #E02619; // correct per new Figma
$secondary: #0066B3;
$tertiary: #0066B3;
$primaryFade: #F16D87;

$primaryFont: Montserrat, system-ui, arial, sans-serif;
$primaryBackgroundImage: url('/assets/zbportal/img/zb-png-background.png');
$primaryBackgroundImageFaded: url('/assets/zbportal/img/zb-png-background.png');

/* Custom Variables */
$primaryLoginBackgroundImage: url('../assets/zbportal/img/primary-login-background.jpg');
$primaryLogo: url('../assets/zbportal/img/zb-logo.svg');
$loginFormBackgroundImage: url('../assets/zbportal/img/login-form-page-background.jpg');
